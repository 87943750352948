/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { useLocation } from 'react-router';
import { parse } from 'qs';
import { getAnalyticsStorageData } from '../../tracking/session/sessionTrackingHelpers';
import dynamicRules from '../DynamicRules';

const useUTMRules = (rules) => {
    const location = useLocation();
    const urlParams = parse((location?.search || '')?.slice(1)) || null;
    let session = null;
    if (typeof window !== 'undefined' && window?.localStorage) {
        session = getAnalyticsStorageData();
    }

    if (!rules?.utm_rules) {
        return true; // return true no need to verify
    }

    let utmSourceData = {};
    if ((location?.search || '').includes('utm_')) {
        utmSourceData = urlParams;
    } else {
        utmSourceData = session?.landingParams;
    }

    const rList = rules?.rules_list || [];
    const load = dynamicRules(rList, utmSourceData);

    return load;
};

export default useUTMRules;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 *
 * @param {*} rulesObj data of rules
 * @param {*} data | the data need to be checked with rules
 * @returns Boolean
 */
const dynamicRules = (rulesObj = {}, data = {}) => {
    const { rules_relation, rule } = rulesObj;
    const everyRule = ({ parameter_name, value, operator }) => {
        const dataValue = data?.[parameter_name] || '';
        switch (operator) {
            case 'not_equal':
                return dataValue !== value;
            case 'contain':
                return dataValue.includes(value);
            case 'not_contain':
                return !dataValue.includes(value);
            case 'equal':
                return dataValue === value;
            default:
                return false;
        }
    };
    const checkWithRulesRelation = (rulesRelation, results) => (rulesRelation === 'and' ? results.every(Boolean) : results.some(Boolean));
    const ruleResults = (rule || []).map(everyRule);
    return checkWithRulesRelation(rules_relation, ruleResults);
};

export default dynamicRules;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { useSelector } from 'react-redux';
import {
    getIsAuthenticatedStatus, getMembershipType, getUserRole, getUserType,
} from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';

const useUserType = (showUserType) => {
    const userRole = useSelector(getUserRole);
    const userType = useSelector(getUserType);
    const membershipType = useSelector(getMembershipType);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    let enableSiteWideBanner = false;
    showUserType?.forEach((item) => {
        switch (item) {
            case 'guest':
                if (!isAuthenticated && (userRole  === 'G' || userRole === '' || userType === 'G' || membershipType === 'G')) {
                    enableSiteWideBanner = true;
                }
                break;
            case 'authenticated':
                if (isAuthenticated) {
                    enableSiteWideBanner = true;
                }
                break;
            case 'passport':
                if (userRole === 'P' || userType === 'P' || membershipType === 'P') {
                    enableSiteWideBanner = true;
                }
                break;
            case 'registered':
                if (userRole === 'R' || userType === 'R' || membershipType === 'R') {
                    enableSiteWideBanner = true;
                }
                break;
            default:
                enableSiteWideBanner = false;
                break;
        }
    });

    return enableSiteWideBanner;
};

export default useUserType;
